import React, { ReactNode } from 'react';
import '../index.css'; // Ajusta la ruta según la ubicación de globals.css

// Definir el tipo de las propiedades que acepta el componente Layout
interface LayoutProps {
  children: ReactNode;
}

// Simulación de la función de fuente personalizada
const fontHeading = {
  fontFamily: "'Manrope', sans-serif",
  '--font-heading': "'Manrope', sans-serif",
};

const fontBody = {
  fontFamily: "'Manrope', sans-serif",
  '--font-body': "'Manrope', sans-serif",
};

// Función auxiliar para concatenar clases
const cn = (...classes: (string | undefined)[]) => classes.filter(Boolean).join(' ');

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div 
      lang="en"
      className={cn(
        'antialiased',
        fontHeading['--font-heading'],
        fontBody['--font-body']
      )}
    >
      {children}
    </div>
  );
};

export default Layout;
