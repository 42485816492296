import React, { useState, useEffect } from 'react';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const Component: React.FC = () => {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = +new Date("2024-09-15") - +new Date();
    let timeLeft: TimeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  if (!mounted) {
    return null;
  }

  return (
    <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg text-center">
        <div className="inline-block rounded-lg bg-muted px-4 py-2 text-sm font-semibold text-muted-foreground uppercase">
          En construcción
        </div>
        <h1 className="mt-2 text-4xl font-bold text-foreground sm:text-5xl">
          ¡Próximamente disponible!
        </h1>
        <p className="mt-4 text-muted-foreground text-lg leading-relaxed">
          Estoy trabajando arduamente para brindarte una experiencia increíble. Vuelve a visitar la página en:
        </p>
        <div className="mt-8 flex flex-wrap lg:flex-nowrap lg:flex-wrap items-center justify-center gap-6">
          <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md w-1/4 min-w-[100px]">
            <span className="text-4xl sm:text-5xl font-extrabold text-primary">{timeLeft.days}</span>
            <span className="text-sm sm:text-base font-medium mt-2 text-muted-foreground uppercase">Días</span>
          </div>
          <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md w-1/4 min-w-[100px]">
            <span className="text-4xl sm:text-5xl font-extrabold text-primary">{timeLeft.hours}</span>
            <span className="text-sm sm:text-base font-medium mt-2 text-muted-foreground uppercase">Horas</span>
          </div>
          <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md w-1/4 min-w-[100px]">
            <span className="text-4xl sm:text-5xl font-extrabold text-primary">{timeLeft.minutes}</span>
            <span className="text-sm sm:text-base font-medium mt-2 text-muted-foreground uppercase">Minutos</span>
          </div>
          <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md w-1/4 min-w-[100px]">
            <span className="text-4xl sm:text-5xl font-extrabold text-primary">{timeLeft.seconds}</span>
            <span className="text-sm sm:text-base font-medium mt-2 text-muted-foreground uppercase">Segundos</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;