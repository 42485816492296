import './App.css';
import Layout from './components/Layout'; // Asegúrate de que la ruta sea correcta
import Building from './components/Building'

function App() {
  return (
    <Layout>
      <Building />
    </Layout>
  );
}

export default App;
